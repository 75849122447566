<template>
  <div>
    <div style="margin: 20px 0 0 20px">
      <MyBreadcrumb></MyBreadcrumb>
    </div>
    <div class="role-main">
      <div class="top-tip">
        提示：题库管理为组织下私有题库，上传前请先点击“分类管理”添加分类，添加分类后再在其分类下添加试题。
      </div>
      <div class="top mt20">
        <div class="top-left">
          <Button type="primary" style="margin-right: 10px;" @click="goCategory">分类管理</Button>
          <Button type="primary" style="margin-right: 10px;" @click="goCategory">单题添加</Button>
        </div>
        <div class="top-right">
          <Input v-model="keyword"
            :placeholder="$t('common_search_keyword')"
            clearable
            search
            :enter-button="true"
            @on-enter="changeKeyword"
            @on-search="changeKeyword"
          />
        </div>
      </div>
      <div v-if="categoryList.length > 1">
        <div class="search">
          <div class="search-item">
            <div class="name">
              一级分类:
            </div>
            <div class="cont">
              <div class="values">
                <div :class="curCategoryId == item.id ? 'active' : ''" v-for="item in categoryList" :key="item.id + 'category'" @click="changeSearch(item,'curCategoryId')">{{item.name}}</div>
              </div>
            </div>
          </div>
          <div class="search-item" v-if="typeList.length > 1">
            <div class="name">
              类型:
            </div>
            <div class="cont">
              <div class="values" >
                <div :class="curTypeId == item.id ? 'active' : ''" v-for="item in typeList" :key="item.id + 'type'" @click="changeSearch(item,'curTypeId')">{{item.name}}</div>
              </div>
            </div>
          </div>
        </div>
        <Table border :columns="columns" :loading="loading" :data="data" @on-sort-change="sortChange"></Table>
        <div class="page">
          <Page :total="total" :page-size-opts="[10,20,50,100]" @on-change="changePage" @on-page-size-change="changePageSize" :current="page" show-total show-sizer show-elevator></Page>
        </div>
      </div>
      <div v-else class="private-no-data">
        <div class="private-no-data-item">
          <img src="../../assets/images/new_icon/one.png" width="140" height="84" />
          <p>【第一步】</p>
          <p>点击“分类管理-添加分类”</p>
        </div>
        <img class="private-no-data-arrow" src="../../assets/images/new_icon/right.png" width="26" height="22" />
        <div class="private-no-data-item">
          <img src="../../assets/images/new_icon/tow.png" width="140" height="84" />
          <p>【第二步】</p>
          <p>在分类管理下批量导入试题/单题添加</p>
        </div>
      </div>

      <DeleModal :status="deleModalStatus" :txt="$t('dataset_data_dele_tip')" :loading="modalLoading" @cancel="cancelDele" @confirm="confirmDele"></DeleModal>
      <Modal
          v-model="batchOffModal"
          title="下架原因"
      >
        <Form ref="batchOffForm" :model="batchOffForm" :rules="ruleBatchOff" :label-width="80">
          <FormItem prop="message" label="原因:">
            <Input type="textarea" v-model="batchOffForm.message" placeholder="请输入原因">
            </Input>
          </FormItem>

        </Form>
        <div slot="footer">
          <Button @click="batchOffCancel">取消</Button>
          <Button type="primary" @click="batchOffConfirm">确定</Button>
        </div>
      </Modal>

      <!-- <Modal
          v-model="correctionModal"
          title="编辑"
          width="70%"
      >
        <div v-if="correctionModal">
          <AddData :addDataModal="correctionModal" :dataInfo="correctionInfo" @changeData="changeData"></AddData> -->
<!--          <EditData :allData="{data:correctionInfo,allCategory:allCategoryList}" :statusObj="statusObj" :levelObj="levelObj" :typesList="typesList" :sourceFroms="sourceFroms" @changeData="changeData"></EditData>-->
        <!-- </div>

        <div slot="footer">
          <Button @click="correctionModal=false">{{ $t('trainingcamp_train_feedback_cancel') }}</Button>
          <Button type="primary" @click="confirmCorrection">{{ $t('trainingcamp_train_feedback_confirm') }}</Button>
        </div>
      </Modal> -->

      <!-- <Modal
          v-model="opListModal"
          title="操作记录"
          width="1000px"
      >
        <Table border :columns="opListColumns" :loading="opListLoading" :data="opListData" ></Table>
        <div style="margin-top: 20px;text-align: right">
          {{opListPage}}
          <Page :total="opListTotal" @on-change="changeOpListPage" @on-page-size-change="changeOpListPageSize" :current="opListPage" show-total show-sizer show-elevator></Page>
        </div>
      </Modal> -->
      <Modal
          v-model="selectDataModal"
          :title="$t('project_select_type')"
          :footer-hide="true"
          :scrollable="true"
          class="select-data-modal"
          width="1000px"
      >
        <div class="tab-list">
          <div class="select-data-cont">
            <CategoryData @create="createGroup"></CategoryData>
          </div>
        </div>
      </Modal>
    </div>
    <el-drawer :size="1200" :visible.sync="correctionModal">
      <div class="drawer-title" slot="title">
        <div class="drawer-title-text">编辑</div>
        <div class="drawer-title-btns">
          <Button @click="correctionModal=false" style="margin-right: 10px;">{{ $t('trainingcamp_train_feedback_cancel') }}</Button>
          <Button type="primary" @click="confirmCorrection">{{ $t('trainingcamp_train_feedback_confirm') }}</Button>
        </div>
      </div>
      <div style="padding: 20px;">
        <div v-if="correctionModal">
          <AddData :addDataModal="correctionModal" :dataInfo="correctionInfo" @changeData="changeData"></AddData>
<!--          <EditData :allData="{data:correctionInfo,allCategory:allCategoryList}" :statusObj="statusObj" :levelObj="levelObj" :typesList="typesList" :sourceFroms="sourceFroms" @changeData="changeData"></EditData>-->
        </div>
      </div>
    </el-drawer>

    <el-drawer :size="1200" :visible.sync="opListModal">
      <div class="drawer-title" slot="title">
        <div class="drawer-title-text">操作记录</div>
      </div>
      <div style="padding: 20px;">
        <Table border :columns="opListColumns" :loading="opListLoading" :data="opListData" ></Table>
        <div style="margin-top: 20px;text-align: right">
          {{opListPage}}
          <Page :total="opListTotal" @on-change="changeOpListPage" @on-page-size-change="changeOpListPageSize" :current="opListPage" show-total show-sizer show-elevator></Page>
        </div>
      </div>
    </el-drawer>
  </div>

</template>

<script>
import DeleModal from '../../components/deleteModal.vue';
import util from '@/utils/tools.js';
import Richtxt from '@/components/production/richtxt.vue';
import CategoryData from "@/views/trainingcamp/components/common/categoryData";
// import EditData from '../dataset/components/data/editData'
import AddData from './components/addData';

export default {
  name: "privateData",
  data(){
    return{
      columns: [
        {
          title: this.$t('dataset_data_table_index'),
          width: 80,
          key:'id',
          render:(h,params)=>{
            return h('span',params.row.id + (params.row.similar_parent_id > 0 ? '('+params.row.similar_parent_id+')' : ''))
          }
        },
        {
          title: this.$t('dataset_data_table_title'),
          key: 'name',
          minWidth:150,
          render: (h, params) => {
            return h('p', {
              style:{
                color:'#2db7f5',
                cursor:'pointer'
              },
              on:{
                click:()=>{
                  const routeUrl = this.$router.resolve({
                    name: 'course-data-detail',
                    params: {
                      id: params.row.id,
                      tab:'cont'
                    }
                  })
                  window.open(routeUrl.href, "_blank");
                }
              }
            }, params.row.title);
          }
        },
        {
          title: this.$t('dataset_data_table_type')+'/'+this.$t('dataset_data_table_level'),
          key: 'type',
          minWidth:100,
          render:(h,params)=>{
            return h('span',this.typeObj[params.row.type] + '/'+this.levelObj[params.row.level])
          }
        },
        // {
        //   title: this.$t('dataset_data_table_level'),
        //   key: 'level',
        //   width:90,
        //   render:(h,params)=>{
        //     return h('span',this.levelObj[params.row.level])
        //   }
        // },
        {
          title: '分类',
          minWidth:150,
          render:(h,params)=>{
            let categoryName = '';
            params.row.categoryList.forEach((item,index)=>{
              if(index < params.row.categoryList.length - 1){
                categoryName += item.name + '-';
              }else{
                categoryName += item.name;
              }

            });
            return h('span',categoryName)
          }
        },
        {
          title: this.$t('dataset_data_table_created_at'),
          key: 'created_at',
          sortable:'custom',
          minWidth:130,
          render: (h, para) => {
            return h('span', util.timeFormatter(
                    new Date(+para.row.created_at * 1000),
                    'yyyy-MM-dd hh:mm'
                )
            );
          }
        },
        {
          title: this.$t('dataset_data_table_updated_at'),
          key: 'updated_at',
          minWidth:130,
          sortable:'custom',
          render: (h, para) => {
            return h('span', para.row.updated_at > 0 ? util.timeFormatter(
                    new Date(+para.row.updated_at * 1000),
                    'yyyy-MM-dd hh:mm'
                ) : ''
            );
          }
        },
        {
          title: this.$t('dataset_data_table_op'),
          width:205,
          fixed:"right",
          render: (h, params) => {
            return h('div', [
              h('el-button', {
                props: {
                  type: 'primary',
                  size: 'mini'
                },
                style: {
                  marginRight: '5px',
                  marginTop: '5px'
                },
                on: {
                  click: () => {
                    this.opListModal = true;
                    this.opListPage = 1;
                    this.opListPageSize = 10;
                    this.curData = params.row;
                    this.getRecordList();
                  }
                }
              }, '操作记录'),
              // h('Button', {
              //   props: {
              //     type: 'primary',
              //     size: 'small'
              //   },
              //   style: {
              //     marginRight: '5px',
              //     marginTop: '5px'
              //   },
              //   on: {
              //     click: () => {
              //       this.correctionModal = true;
              //       this.curData = params.row;
              //       this.correction();
              //     }
              //   }
              // }, this.$t('system_edit')),
              // h('Button', {
              //   props: {
              //     type: 'error',
              //     size: 'small'
              //   },
              //   style: {
              //     marginRight: '5px',
              //     marginTop: '5px'
              //   },
              //   on: {
              //     click: () => {
              //       this.delete(params.row);
              //     }
              //   }
              // }, this.$t('system_dele')),

              h("el-select",{
                props:{
                  transfer: true,//不受表格高度影响,
                  placeholder:this.$t('codekey_manage_more'),
                  size: 'mini',
                  clearable:true,
                  value:""
                },
                style:{
                  width:"75px"
                },
                on:{
                  change:(event)=>{
                    if(event===this.$t('system_edit')){
                      this.correctionModal = true;
                      this.curData = params.row;
                      this.correction();
                    }else if(event===this.$t('system_dele')){
                      this.delete(params.row);
                    }
                  }
                }
              },[
                h("el-option",{
                  props:{
                    value:this.$t('system_edit')
                  }
                },this.$t('system_edit')),
                h("el-option",{
                  props:{
                    value:this.$t('system_dele')
                  },
                  style:{
                    color:"red"
                  }
                },this.$t('system_dele')),
              ])

            ]);
          }
        }
      ],
      data: [],
      loading:false,
      deleteComfirmModal:true,
      deleModalStatus:false,
      modalLoading:false,
      curDeleData:{},
      page:1,
      pageSize:10,
      keyword:'',
      total:0,
      type:'',
      modalTit:'',
      parentCategoryArr:[],
      typeObj:{},
      levelObj:{},
      statusObj:{},
      parentCategoryList:[],
      categoryList:[],  //一级分类
      curCategoryId:'-1', //当前分类
      secondCategoryList:[],  //二级分类
      cursecondCategoryId:'-1', //当前二级分类
      curThirdCategoryId:'-1',//当前三级分类
      thirdCategoryList:[],
      cacheSelectData:{},
      selectModalDataList:[],
      typeList:[],
      statusList:[],
      curTypeId:'-1',
      curStatusId:'-1',
      selectDataLen:0,
      batchOffModal:false,
      batchOffForm:{
        message:'',
      },
      ruleBatchOff:{
        message: [
          { required: true, message: '请输入原因', trigger: 'blur' }
        ],
      },
      sort:'',
      orderby:'',
      correctionModal:false,
      optionNameList:[this.$t('trainingcamp_train_correction_option_a'),this.$t('trainingcamp_train_correction_option_b'),this.$t('trainingcamp_train_correction_option_c'),this.$t('trainingcamp_train_correction_option_d'),this.$t('trainingcamp_train_correction_option_e'),this.$t('trainingcamp_train_correction_option_f'),this.$t('trainingcamp_train_correction_option_g')],
      correctionInfo:{},
      questionType:'',
      typesList:[],
      curData:{},
      rightRateSmall:'',
      rightRateBig:'',
      starTypeList:[],
      starTypeSmall:'',
      starTypeBig:'',
      searchStarTypeSmall:'',
      searchStarTypeBig:'',
      isBatchOff:false,
      isBatchLower:false, //是否为批量处理下架
      opListModal:false,
      opListColumns:[
        {
          title:'题目ID',
          key:'id',
          render:(h,params)=>{
            return h('span',params.row.data.id)
          }

        },
        {
          title:'标题',
          key:'id',
          render:(h,params)=>{
            return h('span',params.row.data.title)
          }

        },
        {
          title:'类型',
          key: 'type',
          render: (h, para) => {
            return h('span', this.typeObj[para.row.data.type]);
          }

        },
        {
          title:'备注',
          key:'message',

        },
        {
          title:'创建人',
          render: (h, para) => {
            return h('span', para.row.user && para.row.user.nickname);
          }

        },
        {
          title:'创建时间',
          key:'id',
          render: (h, para) => {
            return h('span', util.timeFormatter(
                    new Date(+para.row.created_at * 1000), 'MM-dd hh:mm'
                )
            );
          }

        },
      ],
      opListLoading:false,
      opListData:[],
      opListPage:1,
      opListTotal:0,
      opListPageSize:10,
      selectDataModal:false,
      deleType:'',
      sourceFroms:'',
      allCategoryList:[],
      correctionInfoCache:{},
    }
  },
  components:{
    DeleModal,
    Richtxt,
    CategoryData,
    // EditData,
    AddData
  },
  mounted(){
    this.curCategoryId = this.$route.query.id || '-1';
    this.getCategoryList();
    this.getList();
  },
  methods:{
    getRecordList(){
      this.opListLoading = true;
      let params = {
        page:this.opListPage,
        pageSize:this.opListPageSize,
        data_id:this.curData.id
      };
      this.api.dataset.dataRecordList(params).then((res)=>{
        this.opListLoading = false;

        this.opListData = res.list;
        this.opListTotal = Number(res.count);
        // this.typeObj = res.types;
        // this.levelObj = res.levels;
      }).catch((err)=>{
        this.opListLoading = false;
      })
    },
    changeOpListPage(page){
      this.opListPage = page;
      this.getRecordList();
    },
    changeOpListPageSize(pageSize){
      this.opListPageSize = pageSize;
      this.opListPage = 1;
      this.getRecordList();
    },
    changeKeyword(){
      this.page = 1;
      this.getList();
    },
    sortChange(e){
      console.log(e,'orderorderorder')
      if(e.order == 'asc' || e.order == 'desc'){
        this.sort = e.order;
        this.orderby = e.key;
      }else{
        this.sort = '';
        this.orderby = '';
      }
      this.getList();
    },
    getList(){
      this.loading = true;

      let params = {
        user_id:-1,
        page:this.page,
        pageSize:this.pageSize,
        keyword:this.keyword,
        sort:this.sort,
        self_category:1
      };
      if(this.sort){
        params.orderby = this.orderby;
      }
      if(this.curThirdCategoryId && this.curThirdCategoryId != -1){
        params.category_id = this.curThirdCategoryId;
      }else if(this.cursecondCategoryId && this.cursecondCategoryId != -1){
        params.category_id = this.cursecondCategoryId;
      }else if(this.curCategoryId && this.curCategoryId != -1){
        params.category_id = this.curCategoryId;
      }
      if(this.curTypeId && this.curTypeId != -1){
        params.type = this.curTypeId;
      }

      this.api.dataset.dataList(params).then((res)=>{
        this.loading = false;
        if(!res.list.length && this.page > 1){
          this.page = this.page - 1;
          this.getList();
          return;
        }
        this.data = res.list;
        this.total = Number(res.count);
        // this.parentCategoryArr = parentCategoryArr;
        this.typeObj = res.types;
        this.levelObj = res.levels;
        this.statusObj = res.statuses;

        this.statusList = [{
          name:'全部',
          id:'-1'
        }];
        for(let name in res.statuses){
          this.statusList.push({
            name:res.statuses[name],
            id:name
          })
        }


        this.parentCategoryList = res.parentCategoryList;
        this.typeList = [{
          name:'全部',
          id:'-1'
        }];
        for(let name in res.types){
          this.typeList.push({
            name:res.types[name],
            id:name
          })
        }
      }).catch((err)=>{
        this.loading = false;
      })
    },
    delete(data){
      this.curDeleData = data;
      this.deleModalStatus = true;
      this.deleType='';
    },
    cancelDele(){
      this.deleModalStatus = false;
    },
    confirmDele(){
      let data = { }
      data.id = this.curDeleData.id;
      this.modalLoading = true;
      this.api.dataset.dataDelete(data).then((res)=>{
        this.modalLoading = false;
        this.deleModalStatus = false;
        this.getList();
      }).catch((err)=>{
        this.modalLoading = false;
      });
    },
    changePage(page){
      this.page = page;
      this.getList();
    },
    changePageSize(pageSize){
      this.pageSize = pageSize;
      this.page = 1;
      this.getList();
    },
    batchLower(){
      this.batchOffModal = true;
      this.batchOffForm.message = '';
      this.isBatchLower = true;
      this.batchType='lower';
    },
    batchOff(){
      this.batchOffModal = true;
      this.batchOffForm.message = '';
      this.isBatchOff = true;
      this.batchType='off';
    },
    batchOffConfirm(){
      this.$refs['batchOffForm'].validate((valid) => {
        if (valid) {
          let ids = '';
          console.log(this.isBatchOff,'this.isBatchOff')
          if(this.isBatchOff || this.isBatchLower){
            this.cacheSelectData[this.page] = JSON.parse(JSON.stringify(this.selectModalDataList));
            let arr = [];
            for(let name in this.cacheSelectData){
              arr = arr.concat(this.cacheSelectData[name]);
            }
            if(!arr.length){
              this.$Message.warning(this.$t('user_mygroups_select_data_message'));
              return;
            }else{
              ids = arr.map((item)=>{
                return item.id;
              }).join(',');
            }
          }else{
            ids = this.curData.id;
          }
          let status;
          if(this.batchType == 'lower'){
            status = 3;
          }else if(this.batchType == 'off'){
            status = 2;
          }
          let params = {
            id:ids,
            status:status,
            message:this.batchOffForm.message
          };
          this.api.dataset.datasetDataVerify(params).then((res)=>{
            this.$Message.success('操作成功');
            this.cacheSelectData = {};
            this.selectModalDataList = [];
            this.batchOffModal = false;
            this.getList();
          })
        }
      })

    },
    batchOffCancel(){
      this.batchOffModal = false;
    },

    changeSearch(data,name){
      this[name] = data.id;
      this.page = 1;
      this.getList();
    },
    getCategoryList(){
      // let data = {
      //   page:1,
      //   pageSize:10,
      //   category_id:this.curCategoryId,
      //   category_second_id:this.cursecondCategoryId,
      //   is_hide_children:1,
      //   site_id:-1,
      //   user_id:-1,
      // };

      this.api.course.selfThirdList({
        user_id:"-1"
      }).then((res)=>{

        //一级标签
        this.categoryList = [
          {
            name:'全部',
            id:'-1'
          },
          ...res.list
        ];
      })
    },
    getDetail(){
      let params = {
        id:this.curData.id
      };
      // this.loading = true;
      this.correctionInfo = {};
      this.allCategoryList = [];
      this.correctionInfoCache = {};
      this.api.dataset.dataDetail(params).then((res)=>{
        // this.loading = false;
        this.correctionInfo = JSON.parse(JSON.stringify(res.info));
        this.correctionInfo.labelKeywords = this.correctionInfo.labelKeywords.map((item)=>{
          return item.label_id
        });
        this.correctionInfo.labelKeypoints = this.correctionInfo.labelKeypoints.map((item)=>{
          return item.label_id
        });


        this.questionType = res.types[this.correctionInfo.type];
        this.typesList = [];
        for(let name in res.types){
          this.typesList.push({
            name:res.types[name],
            value:name
          })
        }
        this.sourceFroms = res.source_froms;

        this.$set(this.correctionInfo,'categoryIds',[]);
        for(let name in this.correctionInfo.categoryList){
          this.correctionInfo.categoryIds.push(name)
        }

        this.allCategoryList = [];
        for(let name in res.info.categoryList){
          let names = res.info.categoryList[name].map(sItem=>{
            return sItem.name+'(id:'+sItem.id+')'
          }).join('-');
          this.allCategoryList.push({
            name:names,
            value:name
          })
        }
        this.correctionModal = true;
      }).catch((err)=>{
        // this.loading = false;
      })
    },
    changeData(data){
      this.correctionInfoCache = JSON.parse(JSON.stringify(data));
    },
    correction(){

      this.getDetail();
    },
    saveCurData(curData,data,name){
      data[name] = curData;
    },
    // changeQuestionType(){
    //   this.correctionInfo.type = this.typesList.filter((item)=>{
    //     return item.name == this.questionType
    //   })[0].value;
    //   this.correctionInfo.options.forEach((item)=>{
    //     item.is_correct = '0';
    //   });
    // },
    confirmCorrection(){  //确认纠错
      this.correctionInfo = JSON.parse(JSON.stringify(this.correctionInfoCache));
      // if(!this.correctionInfo.categoryIds.length){
      //   this.$Message.warning('请选择分类');
      //   return;
      // }
      if(!this.correctionInfo.name){  //标题
        this.$Message.warning(this.$t('trainingcamp_train_warning_tit'));
        return;
      }
      if(this.correctionInfo.type == '1' || this.correctionInfo.type == '2' || this.correctionInfo.type == '3' || this.correctionInfo.type == '4') {
        if (!this.correctionInfo.options.length) { //选项
          this.$Message.warning(this.$t('trainingcamp_train_warning_add_option'));
          return;
        }
        let len = this.correctionInfo.options.filter((item) => {
          return item.is_correct == '1';
        }).length

        if (!len && this.correctionInfo.type != '4') { //正确选项
          this.$Message.warning(this.$t('trainingcamp_train_warning_select'));
          return;
        }
        for (let i = 0; i < this.correctionInfo.options.length; i++) {
          if (!this.correctionInfo.options[i].name) {
            this.$Message.warning(this.$t('trainingcamp_train_warning_input_cont'));
            return;
            break;
          }
        }
        if (this.correctionInfo.type == '1' || this.correctionInfo.type == '3') {
          if (len > 1) {
            this.$Message.warning('只能选择一个正确答案');
            return;
          }
        }
      }

      if(!this.correctionInfo.labelKeywords.length){
        this.$Message.warning('请选择标签');
        return;
      }


      let params = {
        id:this.correctionInfo.id,
        name:this.correctionInfo.name,
        explain:this.correctionInfo.explain,
        options:JSON.stringify(this.correctionInfo.options),
        type:this.correctionInfo.type,
        level:this.correctionInfo.level,
        // status:this.correctionInfo.status,
        source:this.correctionInfo.source,
        label_keyword_ids:this.correctionInfo.labelKeywords.join(','),
        label_keypoint_ids:this.correctionInfo.labelKeypoints.join(','),
        // category_ids:this.correctionInfo.categoryIds.join('|'),
      };

      this.api.dataset.dataUpdate(params).then((res)=>{
        this.correctionModal = false;
        this.$Message.success(this.$t('trainingcamp_train_cont_edit_success'));
        // this.getInfo()
        this.getList();
        this.$emit('edit')
      })
    },
    importCategory(){
      this.selectDataModal = true;
    },
    createGroup(data){
      let params = {
        category_id:data
      };
      this.api.dataset.groupCreate(params).then((res)=>{
        this.selectDataModal = false;

        this.$router.push({
          path:'/dataset/import-category-data',
          query:{
            groupId:res.id,
          }
        })
      })
    },
    goCategory(){
      this.$router.push({
        path:'/course/category'
      })
    }
  }
}
</script>

<style scoped lang="scss">
.role-main{
  margin: 20px;
  padding:20px;
  background: #FFFFFF;
  box-shadow: 0px 2px 12px 0px rgba(189,189,189,0.4100);
  border-radius: 4px;
  .search{
    margin-top: 20px;
    margin-bottom: 20px;
    .search-item{
      margin-bottom: 15px;
      display: flex;
      justify-content: flex-start;
      font-size: 14px;

      .name{
        padding: 5px 0;
        width: 75px;
        text-align: right;
      }
      .cont{
        margin-left: 15px;
        flex: 1;
        display: flex;
        justify-content: space-between;

        .values{
          display: flex;
          justify-content: flex-start;
          flex-flow: wrap;
          transition: max-height 0.3s linear;
          overflow: hidden;

          >div{
            padding: 5px 10px;
            //margin: 0 10px 10px 5px;
            cursor: pointer;
          }
          >div.active{
            background-color: #2d8cf0;
            color:#FFFFFF;
            border-radius: 4px;
          }
        }
        .more{
          padding: 5px 0;
          width: 80px;
          text-align: right;
          cursor: pointer;
          .more-icon{
            transition: transform 0.3s linear;
          }
        }
      }
    }
  }
  .top{
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .top-right{
      width: 200px;
      text-align: right;
    }
  }
  .page{
    margin-top: 20px;
    text-align: right;
  }
  .select-num{
    margin-bottom: 20px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    >p{
      margin-right: 20px;
      >span{
        padding: 0 5px;
        color:#2d8cf0;
      }
    }
  }
}
.private-no-data{
  margin: 60px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: 400;
  color: #666666;
  text-align: center;

  .private-no-data-item{
    line-height: 26px;
    >img{
      margin-bottom: 10px;
    }

  }
  .private-no-data-arrow{
    margin: -80px 122px 0 122px;

  }
}
.correction{  //纠错
  height: 500px;
  overflow-y: auto;
  .type{
    font-size: 20px;
    margin-bottom: 20px;
  }
  .tit,
  .option-item,
  .analysis{
    margin-bottom: 20px;
    display: flex;
    justify-content: flex-start;
    .left{
      width: 100px;
      text-align: right;
      font-size: 14px;
      overflow: hidden;


      .correct{
        margin: 5px 0;
        border: 1px solid #dcdee2;
        border-radius: 4px;
        padding: 5px 0;
        text-align: center;
        cursor: pointer;
      }
      .dele{
        float: right;
        width: 50px;
        color:#FFFFFF;
        border-radius: 4px;
        padding: 5px 0;
        text-align: center;
        background-color: #ed4014;
        cursor: pointer;
      }
      .active{
        border: 1px solid #19be6b;
        background-color: #19be6b;
        color:#FFFFFF;
      }
    }
    .right{
      margin-left: 20px;
      flex: 1;
      border: 1px solid #dcdee2;
    }
  }
  .analysis{
    margin-top: 20px;
  }
}
::v-deep .el-drawer {
  .el-drawer__header {
    margin-bottom: 0;
  }

  .drawer-title {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .drawer-title-text {
      font-size: 18px;
      color: #333333;
      font-weight: bold;
    }

    .drawer-title-btns {
      padding-right: 40px;
    }
  }
}
</style>
